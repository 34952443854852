import {Formik} from "formik";
import {useContext} from "react";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";
import ApiManager from "../../api";
import {formServerValidate} from "../../utils/helper";

function ApiFormValidation({children, onAfterSubmit, initialValues, validationSchema, targetRoute, targetRouteParams}) {

    const {currentItem, route, routeParams, setLastFetchedDate} = useContext(ApiWrapperContext)

    const formFields = Object.keys(validationSchema.json().fields).map(field => [field, ""]);
    let initialFieldValues = initialValues || Object.fromEntries(formFields);

    initialFieldValues = currentItem?.isEdit ? currentItem.item : initialFieldValues;

    const createOrUpdate = async (values) => {
        const apiManager = new ApiManager();
        if (currentItem?.isEdit) {
            return await apiManager.update(targetRoute || route, values, Object.assign({id: currentItem.item.id}, targetRouteParams || routeParams))
        } else {
            return await apiManager.create(targetRoute || route, values, {...currentItem?.item?.routeParams, ...(targetRouteParams || routeParams)})
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialFieldValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => await formServerValidate(async () => {
                await createOrUpdate(values)
                setLastFetchedDate(Date.now());

                if (onAfterSubmit) {
                    onAfterSubmit({values, actions})
                }
            }, actions)}
        >
            {children}
        </Formik>
    );
}

export default ApiFormValidation;
