import {Alert, Button, Form as BootstrapForm} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBugs, faLightbulb} from "@fortawesome/free-solid-svg-icons";
import InputGroup from "react-bootstrap/InputGroup";
import ButtonOverlay from "../../../components/buttons/ButtonOverlay";
import ApiManager from "../../../api";
import {useFormikContext} from "formik";
import {useState} from "react";

const randomizerRoute = 'randomize-domain';

function DomainTemplateField({name}) {

    const {values, handleChange, errors} = useFormikContext();
    const [showTestAlert, setShowTestAlert] = useState(false);
    const [testResult, setTestResult] = useState([]);

    const handleTest = (template) => () => {

        new ApiManager()
            .create(randomizerRoute, {
                template,
                count: 5
            })
            .then(response => setTestResult(response.data.domain))

        setShowTestAlert(true)
    }

    return <BootstrapForm.Group className="mb-3" controlId="template">
        <Alert variant="info">
            <strong>
                <FontAwesomeIcon icon={faLightbulb} style={{"color": "#FDF9D8"}}/> Памятка по шаблону
            </strong>
            <div>{`{текст 1|текст 2|текст 3}`} - перебор</div>
            <div>{`[текст 1|текст 2|текст 3]`} - перестановки</div>
            <div>{`[+разделитель+текст 1|текст 2|текст 3]`} - перестановки с разделителем</div>
            <div>{`{ \} \| \[ \] \+ \\ - экранизация спецсимволов`}</div>
        </Alert>
        <BootstrapForm.Label>Шаблон домена</BootstrapForm.Label>
        <InputGroup className="mb-3">
            <BootstrapForm.Control
                as="textarea"
                type="text"
                name={name}
                value={values[name]}
                onChange={handleChange}
                isInvalid={!!errors[name]}
            />
            <ButtonOverlay tooltip="Тест">
                <Button onClick={handleTest(values[name])} variant="outline-success">
                    <FontAwesomeIcon icon={faBugs}/>
                </Button>
            </ButtonOverlay>
            <BootstrapForm.Control.Feedback type="invalid">
                {errors[name]}
            </BootstrapForm.Control.Feedback>
        </InputGroup>
        <Alert
            show={showTestAlert}
            variant="info"
            onClick={() => setShowTestAlert(false)}
            dismissible={true}
        >
            <strong>Результат тестирования</strong>
            <div>
                {
                    typeof testResult === 'object' ?
                        testResult.map(domain => (<div>{domain}</div>)) :
                        testResult
                }
            </div>
        </Alert>
    </BootstrapForm.Group>
}

export default DomainTemplateField
