import {useFormikContext, ErrorMessage} from "formik";
import {Card, Col, Form} from "react-bootstrap";

import * as Yup from 'yup';
import {useEffect, useState} from "react";
import ApiManager from "../../../api";
import DomainTemplateField from "../components/DomainTemplateField";
import CloudflareDependentSelect from "../components/CloudflareDependentSelect";
import SwitchableInput from "../../../components/form/SwitchableInput";
import {truncateString} from "../../../utils/helper";
import registrarsList from "../../Registrars/registrars.json";

const cloudflareProfileListRoute = 'cloudflare.profile-list';
const registrarRoute = 'registrar';

export const validationSchema = Yup.object().shape({
    transferMode: Yup.string().required('Поле обязательно для заполнения.'),
    siteType: Yup.string().required('Поле обязательно для заполнения.'),
    boilerplateId: Yup.string().required('Поле обязательно для заполнения.'),
    serverId: Yup.string().required('Поле обязательно для заполнения.')
})

export const defaultFormValues = {
    templateManualInput: false,
    template: '',
    registrars: [],
    registrarManualInput: false,
    registrarId: '',
    cloudflareManualInput: false,
    cloudflareId: '',
    cloudflareGroupId: '',
    siteQuantity: 1,
    noteSticky: '',
    noteFloat: '',
    ampMainTemplateManual: false,
    ampMainBoilerplateId: '',
    ampMainCategoryId: '',
    ampMainTemplate: '',
    transferMode: '',
    redirectStrategy: 'default',
    serverId: '',
    siteType: '',
    boilerplateId: '',
}

export function resetForm({actions}) {
    actions.resetForm({values: defaultFormValues})
}

export default function SitesListDeployForm({
                                                serverProfile,
                                                boilerplate,
                                                siteCategories
                                            }) {
    const {values, handleChange, errors, setFieldValue} = useFormikContext();
    const [cloudflareProfile, setCloudflareProfile] = useState([]);
    const [registrarProfile, setRegistrarProfile] = useState([]);

    useEffect(() => {
        const supportAutoRegistration = Object.keys(registrarsList).filter(registrar => registrarsList[registrar].isSupportAutoRegistration);

        const apiManager = new ApiManager();

        apiManager
            .request(cloudflareProfileListRoute)
            .then(response => {
                setCloudflareProfile(response.data)
            })

        apiManager.request(registrarRoute, {
            paginator: 'without'
        })
            .then(data => setRegistrarProfile(data.filter(profile => supportAutoRegistration.includes(profile.registrarId))))
    }, [])

    return (
        <Form>
            <SwitchableInput
                mainTitle="Шаблон домена (настройки оффера)"
                onSwitch={(value) => setFieldValue('templateManualInput', value)}
                formInput={<DomainTemplateField key="template" name="template"/>}
            />

            <ErrorMessage
                name="templateManualInput"
                render={msg => <div className="d-block invalid-feedback mb-2">{msg}</div>}
            />

            <Form.Group as={Col} md="3" className="mb-3" controlId="siteQuantity">
                <Form.Label>Количество сайтов</Form.Label>
                <Form.Control
                    name="siteQuantity"
                    type="number"
                    value={values.siteQuantity}
                    onChange={handleChange}
                    isInvalid={!!errors.siteQuantity}
                    min="1"
                    max="100"
                />
                <Form.Control.Feedback type="invalid">
                    {errors.siteQuantity}
                </Form.Control.Feedback>
            </Form.Group>
            <SwitchableInput
                mainTitle="Аккаунт Cloudflare (настройки оффера)"
                onSwitch={(value) => setFieldValue('cloudflareManualInput', value)}
                formInput={<CloudflareDependentSelect cloudflareProfile={cloudflareProfile}/>}
            />

            <ErrorMessage
                name="cloudflareManualInput"
                render={msg => <div className="d-block invalid-feedback mb-2">{msg}</div>}
            />

            <SwitchableInput
                mainTitle="Регистратор (настройки оффера)"
                onSwitch={(value) => setFieldValue('registrarManualInput', value)}
                formInput={<Form.Group className="mb-3" controlId="registrarId">
                    <Form.Label>Регистратор</Form.Label>
                    <Form.Select
                        name="registrarId"
                        value={values.registrarId}
                        onChange={handleChange}
                        isInvalid={!!errors.registrarId}
                        aria-label="Выберите регистратора"
                    >
                        <option value="">Выберите профиль регистратора</option>
                        {registrarProfile.map(
                            profile => <option
                                key={profile.id}
                                value={profile.id}>
                                {profile.title} ({registrarsList[profile.registrarId].title})
                            </option>
                        )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {errors.registrarId}
                    </Form.Control.Feedback>
                </Form.Group>}
            />

            <ErrorMessage
                name="registrarManualInput"
                render={msg => <div className="d-block invalid-feedback mb-2">{msg}</div>}
            />

            <Form.Group className="mb-3" controlId="noteSticky">
                <Form.Label>Примечание (фиксированное)</Form.Label>
                <Form.Control
                    as="textarea"
                    name="noteSticky"
                    rows={5}
                    value={values.noteSticky}
                    onChange={handleChange}
                    type="text"
                    isInvalid={!!errors.noteSticky}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.noteSticky}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="noteFloat">
                <Form.Label>Примечание (динамическое)</Form.Label>
                <Form.Control
                    as="textarea"
                    name="noteFloat"
                    rows={5}
                    value={values.noteFloat}
                    onChange={handleChange}
                    type="text"
                    isInvalid={!!errors.noteFloat}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.noteFloat}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="serverId">
                <Form.Label>Cервер</Form.Label>
                <Form.Select
                    name="serverId"
                    value={values.serverId}
                    onChange={handleChange}
                    isInvalid={!!errors.serverId}
                    aria-label="Выберите сервер"
                >
                    <option value="">Выберите cервер</option>
                    {serverProfile.length > 0 ? serverProfile.map(server => (
                        <option key={server.id} value={server.id}>{server.title} ({server.ipAddress})</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.serverId}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="transferMode">
                <Form.Label>Режим переезда</Form.Label>
                <Form.Select
                    name="transferMode"
                    value={values.transferMode}
                    onChange={handleChange}
                    isInvalid={!!errors.transferMode}
                    aria-label="Режим переезда"
                >
                    <option value="">Выберите режим переезда</option>
                    <option value="manual">Ручной</option>
                    <option value="auto">Автоматический</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.transferMode}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="redirectStrategy">
                <Form.Label>Тип редиректа</Form.Label>
                <Form.Select
                    name="redirectStrategy"
                    value={values.redirectStrategy}
                    onChange={handleChange}
                    isInvalid={!!errors.redirectStrategy}
                    aria-label="Тип редиректа"
                >
                    <option value="default">По цепочке до первого сайта</option>
                    <option value="second">По цепочке до второго сайта, далее со второго на первый</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.redirectStrategy}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="siteType">
                <Form.Label>Тип сайта</Form.Label>
                <Form.Select
                    name="siteType"
                    value={values.siteType}
                    onChange={handleChange}
                    isInvalid={!!errors.siteType}
                    aria-label="Тип сайта"
                >
                    <option value="">Выберите тип сайта</option>
                    <option value="default">Стандартный сайт</option>
                    <option value="ampMain">Стандартный сайт + амп сайт</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.siteType}
                </Form.Control.Feedback>
            </Form.Group>
            {values.siteType === 'ampMain' && <Card className="mb-3">
                <Card.Body>
                    <Card.Subtitle className="mb-3">Параметры генерации АМП</Card.Subtitle>
                    <SwitchableInput
                        mainTitle="Шаблон домена (настройки оффера для новых сайтов)"
                        onSwitch={(value) => setFieldValue('ampMainTemplateManual', value)}
                        formInput={<DomainTemplateField key="ampMainTemplate" name="ampMainTemplate"/>}
                    />
                    <ErrorMessage
                        name="ampMainTemplateManual"
                        render={msg => <div className="d-block invalid-feedback mb-2">{msg}</div>}
                    />
                    <Form.Group className="mb-3" controlId="ampMainBoilerplateId">
                        <Form.Label>Шаблон сайта</Form.Label>
                        <Form.Select
                            name="ampMainBoilerplateId"
                            value={values.ampMainBoilerplateId}
                            onChange={handleChange}
                            isInvalid={!!errors.ampMainBoilerplateId}
                            aria-label="Шаблон сайта"
                        >
                            <option value="">Выберите шаблон сайта</option>
                            {boilerplate.length > 0 ? boilerplate.map(boilerplate => (
                                <option key={boilerplate.id}
                                        value={boilerplate.id}>{boilerplate.title} {boilerplate?.note && `(${truncateString(boilerplate?.note ?? '', 30)})`}</option>
                            )) : ''}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {errors.ampMainBoilerplateId}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="ampMainCategoryId">
                        <Form.Label>Оффер (для новых сайтов)</Form.Label>
                        <Form.Select
                            name="ampMainCategoryId"
                            value={values.ampMainCategoryId}
                            onChange={handleChange}
                            isInvalid={!!errors.ampMainCategoryId}
                            aria-label="Категория"
                        >
                            <option value="">Выберите категорию</option>
                            {siteCategories.length > 0 ? siteCategories.map(category => (
                                <option key={category.id} value={category.id}>{category.title}</option>
                            )) : ''}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {errors.ampMainCategoryId}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Card.Body>
            </Card>
            }

            <Form.Group className="mb-3" controlId="boilerplateId">
                <Form.Label>Шаблон сайта</Form.Label>
                <Form.Select
                    name="boilerplateId"
                    value={values.boilerplateId}
                    onChange={handleChange}
                    isInvalid={!!errors.boilerplateId}
                    aria-label="Шаблон сайта"
                >
                    <option value="">Выберите шаблон сайта</option>
                    {boilerplate.length > 0 ? boilerplate.map(boilerplate => (
                        <option key={boilerplate.id}
                                value={boilerplate.id}>{boilerplate.title} {boilerplate?.note && `(${truncateString(boilerplate?.note ?? '', 30)})`}</option>
                    )) : ''}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.boilerplateId}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    );
}
