import {Button, ButtonGroup, Card, Form as BootstrapForm, Modal} from "react-bootstrap";
import ButtonOverlay from "../../components/buttons/ButtonOverlay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useRef, useState} from "react";
import {MultiModalContext} from "../../context/MultiModalContext";
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import registrarsList from "../Registrars/registrars.json"
import ApiManager from "../../api";
import {formServerValidate, profileGroupFactory} from "../../utils/helper";
import CloudflareDependentSelect from "./components/CloudflareDependentSelect";
import DomainTemplateField from "./components/DomainTemplateField";

const validationSchema = Yup.object().shape({
    registrars: Yup.array().of(
        Yup.object().shape({
            profileId: Yup.string().required('Выберите профиль')
        })
    )
        .required('Задайте профиль')
        .min(1, 'Добавьте хотя бы один профиль'),
    template: Yup.string().required('Поле обязательно для заполнения.')
});

const categorySettingRoute = 'sites.category.setting';
const registrarRoute = 'registrar';

const defaultFormValues = {
    template: '',
    registrars: [],
    cloudflareId: '',
    cloudflareGroupId: '',
    isEnabled: true
}

export default function SettingsModal({windowId, ...rest}) {
    const {isModalOpen, closeModal, getModalPayload} = useContext(MultiModalContext)
    const [isUpdating, setUpdating] = useState(false);
    const [initValues, setInitValues] = useState(defaultFormValues);
    const [registrarProfile, setRegistrarProfile] = useState([]);
    const [cloudflareProfile, setCloudflareProfile] = useState([]);
    const [cloudflareProfileIdMap, setCloudflareProfileIdMap] = useState({})
    const [cloudflareGroupIdMap, setCloudflareGroupIdMap] = useState({})

    const handleClose = () => {
        closeModal(windowId);
    };

    const formRef = useRef();

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }

    const handleFormSubmit = async (values, actions) => await formServerValidate(async () => {
        setUpdating(true);

        try {
            const api = new ApiManager();
            await api.create(categorySettingRoute, values, {categoryId: getModalPayload(windowId)?.id});
            setUpdating(false);
        } catch (e) {
            setUpdating(false);
            throw e;
        }
    }, actions)

    useEffect(() => {
        if (!isModalOpen(windowId)) return;

        const supportAutoRegistration = Object.keys(registrarsList).filter(registrar => registrarsList[registrar].isSupportAutoRegistration);

        const apiManager = new ApiManager();

            apiManager.request(registrarRoute, {
                paginator: 'without'
            })
            .then(data => setRegistrarProfile(data.filter(profile => supportAutoRegistration.includes(profile.registrarId))))

        apiManager
            .request('cloudflare.profile-list', {
                filters: {
                    'categoryDomainCount': getModalPayload(windowId)?.id
                }
            })
            .then(response => {
                setCloudflareProfile(response.data)
                setCloudflareGroupIdMap(profileGroupFactory(response.data?.groups ?? []));
                setCloudflareProfileIdMap(profileGroupFactory(response.data?.profiles ?? []));
            })

    }, [windowId, getModalPayload(windowId)?.id])

    useEffect(() => {
        if (!isModalOpen(windowId)) return;

        new ApiManager()
            .request(categorySettingRoute, {categoryId: getModalPayload(windowId)?.id})
            .then(response => setInitValues(
                Object.keys(response.data).length > 0 ?
                    response.data :
                    defaultFormValues
                )
            )

    }, [isModalOpen(windowId), windowId])

    return (
        <Modal {...rest}
               size="lg"
               show={isModalOpen(windowId)}
               onHide={handleClose}
               fullscreen={false}
               aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редактирование настроек {getModalPayload(windowId)?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Header>Автоматическая регистрация доменов</Card.Header>
                    <Card.Body>

                        <Card.Text>
                            <Formik
                                innerRef={formRef}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                initialValues={initValues}
                                onSubmit={handleFormSubmit}
                            >
                                {({values, setFieldValue, handleSubmit}) => (
                                    <Form onSubmit={handleSubmit}>

                                        <DomainTemplateField name="template"/>
                                        <FieldArray name="registrars">
                                            {({remove, push}) => (
                                                <div>
                                                    <Button onClick={() => push({profileId: ''})} className="mb-3">
                                                        Добавить профиль регистратора
                                                    </Button>

                                                    {values?.registrars?.length > 0 && values?.registrars?.map((rule, index) => (
                                                        <div className="d-flex flex-row justify-content-start align-items-baseline mb-1">
                                                            <div className="input-group has-validation">
                                                                <Field
                                                                    as="select"
                                                                    className="form-select"
                                                                    name={`registrars.${index}.profileId`}
                                                                    style={{'width': '25%', 'margin-right': '.5rem'}}
                                                                    aria-label="Профиль регистратора"
                                                                >
                                                                    <option key={null} value="">Выберите профиль</option>
                                                                    {registrarProfile.map(
                                                                        profile => <option
                                                                            key={profile.id}
                                                                            value={profile.id}>
                                                                            {profile.title} ({registrarsList[profile.registrarId].title})
                                                                        </option>
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                    style={{'display': 'block'}}
                                                                    name={`registrars.${index}.profileId`}
                                                                />
                                                            </div>
                                                            <div className="d-flex m-2">
                                                                <ButtonOverlay className="flex-grow-1" tooltip="Удалить">
                                                                    <Button onClick={remove} size="sm"
                                                                            variant="danger" className="m-1">
                                                                        <FontAwesomeIcon icon={faTrash}/>
                                                                    </Button>
                                                                </ButtonOverlay>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                        <ErrorMessage
                                            component="div"
                                            name={`registrars`}
                                            render={msg => <div className="invalid-feedback" style={{'display': 'block'}}>{typeof msg === 'string' ? msg : null}</div>}
                                        />
                                        <BootstrapForm.Group className="mb-3">
                                            <BootstrapForm.Label>Cloudflare</BootstrapForm.Label>
                                            <CloudflareDependentSelect cloudflareProfile={cloudflareProfile}/>
                                        </BootstrapForm.Group>
                                        <BootstrapForm.Check
                                            checked={values.isEnabled}
                                            onChange={(event) => {
                                                setFieldValue('isEnabled', event.target.checked)
                                            }}
                                            name="isEnabled"
                                            type="switch"
                                            id="isEnabled"
                                            label="Включить авторегистрацию"
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <ButtonOverlay tooltip="Применить">
                        <Button disabled={isUpdating} onClick={handleSubmit} variant="info">
                            {isUpdating ? <><FontAwesomeIcon spin={true} icon={faSpinner}/> Ждите...</> : 'Применить'}
                        </Button>
                    </ButtonOverlay>
                    <ButtonOverlay tooltip="Закрыть текущее модальное окно">
                        <Button variant="danger" onClick={handleClose}>Закрыть</Button>
                    </ButtonOverlay>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}
